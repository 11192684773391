var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.error)?_c('p',{staticClass:"error",domProps:{"innerHTML":_vm._s(_vm.error)}}):_vm._e(),_c('h1',[_vm._v("Manual Extras")]),_c('p',[_vm._v("Please add all extra containers being picked up.")]),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"7","sm":"7"}},[_c('v-select',{attrs:{"items":_vm.containers,"item-text":"container.container","item-value":"container.id","label":"Container"},model:{value:(_vm.container),callback:function ($$v) {_vm.container=$$v},expression:"container"}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"3","sm":"3"}},[_c('v-select',{attrs:{"items":_vm.qtys,"label":"Qty"},model:{value:(_vm.qty),callback:function ($$v) {_vm.qty=$$v},expression:"qty"}})],1),(_vm.container == 8 || _vm.container == 9)?[_c('v-col',{staticClass:"d-flex",attrs:{"col":"10","sm":"10"}},[_c('v-select',{attrs:{"items":_vm.paper_locations,"label":"Locations"},model:{value:(_vm.paper_location),callback:function ($$v) {_vm.paper_location=$$v},expression:"paper_location"}}),(_vm.paper_location == 'Other')?[_c('v-text-field',{attrs:{"label":"Other Location"},model:{value:(_vm.other_location),callback:function ($$v) {_vm.other_location=$$v},expression:"other_location"}})]:_vm._e()],2)]:_vm._e(),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"2","sm":"2"}},[_c('v-btn',{attrs:{"depressed":"","small":"","color":"success","disabled":_vm.qty == '' ||
            _vm.container == '' ||
            (_vm.container == '8' && _vm.paper_location == '') ||
            (_vm.container == '9' && _vm.paper_location == '') ||
            (_vm.container == '8' &&
              _vm.paper_location == 'Other' &&
              _vm.other_location == '') ||
            (_vm.container == '9' &&
              _vm.paper_location == 'Other' &&
              _vm.other_location == '')},on:{"click":_vm.addContainers}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1)],1)],2)],1),(!_vm.result)?_c('div',{staticClass:"text-center mt-5"},[_c('v-btn',{staticClass:"mx-auto white--text",attrs:{"color":"red"},on:{"click":_vm.extras}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"mx-auto white--text ml-4",attrs:{"color":"primary"},on:{"click":_vm.extras}},[_vm._v(" Continue ")])],1):_vm._e(),(_vm.result)?_c('p',{staticClass:"alert success",domProps:{"innerHTML":_vm._s(_vm.msg)}}):_vm._e(),(_vm.assigned.length > 0)?_c('div',{staticClass:"mt-5"},[_c('h3',[_vm._v("EXTRA CONTAINERS")]),_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Container")]),_c('th',{staticClass:"text-left"},[_vm._v("Delete")])])]),_c('tbody',_vm._l((_vm.assigned),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(" "+_vm._s(item.container)+" "),(item.paper_location)?_c('span',[_vm._v(" - "+_vm._s(item.paper_location))]):_vm._e()]),_c('td',[_c('v-btn',{staticClass:"mx-auto white--text",attrs:{"color":"red","elevation":"0","fab":"","small":"","dark":""},on:{"click":function($event){return _vm.deleteContainer(index)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)])}),0)]},proxy:true}],null,false,2987925696)})],1):_vm._e(),(_vm.result)?_c('div',{staticClass:"text-center mt-5"},[_c('v-btn',{staticClass:"mx-auto white--text mr-4",attrs:{"color":"primary"},on:{"click":_vm.extras}},[_vm._v(" Continue ")]),_c('v-btn',{staticClass:"mx-auto white--text",attrs:{"color":"red"},on:{"click":_vm.extras}},[_vm._v(" Go Back ")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }